import request from '@/utils/requestV2'
const qs = require('qs')

// 查询招商项目列表
export function listInvestmentProject (data) {
  return request({
    url: '/ooh-invest/v1/invest/listInvestmentProject',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询单个招商项目详情
export function getInvestmentProject (data) {
  return request({
    url: '/ooh-invest/v1/invest/getInvestmentProject',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 创建招商项目
export function createInvestmentProject (data) {
  return request({
    url: '/ooh-invest/v1/invest/createInvestmentProject',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 编辑招商项目
export function editInvestmentProject (data) {
  return request({
    url: '/ooh-invest/v1/invest/editInvestmentProject',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除招商项目
export function deleteInvestmentProject (data) {
  return request({
    url: '/ooh-invest/v1/invest/deleteInvestmentProject',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 上架招商项目
export function submitInvestmentProject (data) {
  return request({
    url: '/ooh-invest/v1/invest/submitInvestmentProject',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 公示招商项目
export function publicProject (data) {
  return request({
    url: '/ooh-invest/v1/invest/publicProject',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询项目下的公示记录列表
export function listInvestPublic (data) {
  return request({
    url: '/ooh-invest/v1/invest/listInvestPublic',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 下架招商项目
export function offProject (data) {
  return request({
    url: '/ooh-invest/v1/invest/offProject',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取合同模板
export function listContractTemplate (data) {
  return request({
    url: '/ooh-invest/v1/invest/listContractTemplate',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询招商项目的资产列表
export function listDevice (data) {
  return request({
    url: '/ooh-invest/v1/invest/listDevice',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询项目的报名客户列表
export function listSinInBusiness (data) {
  return request({
    url: '/ooh-invest/v1/invest/listSinInBusiness',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 编辑客户的备注
export function editAdvertiserRemark (data) {
  return request({
    url: '/ooh-invest/v1/invest/editAdvertiserRemark',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 退款给客户
export function orderRefund (data) {
  return request({
    url: '/ooh-invest/v1/invest/orderRefund',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 招商项目转合同
export function changeProjectToContract (data) {
  return request({
    url: '/ooh-invest/v1/invest/changeProjectToContract',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 编辑招商的发布内容
export function editInvestContent (data) {
  return request({
    url: '/ooh-invest/v1/invest/editInvestContent',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 增加租赁空间
export function addLeaseSpace (data) {
  return request({
    url: '/ooh-invest/v1/invest/addLeaseSpace',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询项目下的租赁空间
export function listSpace (data) {
  return request({
    url: '/ooh-invest/v1/invest/listSpace',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除租赁空间
export function deleteSpace (data) {
  return request({
    url: '/ooh-invest/v1/invest/deleteSpace',
    method: 'post',
    data: qs.stringify(data)
  })
}
